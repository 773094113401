img.login-logo {
    max-height: 40px;
    float: right;
}

.list-item-img {
    max-width: 120px;
}

.paginator .form-control {
    width: auto;
    float: left;
    margin: 0px 5px;
}

.paginator span {
    float: left;
    line-height: 2em;
}

img.file-picker-thumbnail {
    width: 100%;
    max-width: 200px;
    padding: 5px;
    border: 1px solid #ddd;
    margin-bottom: 2px;
}

.multiple-remove-button {
    position: absolute;
    right: 20px;
    top: 5px;
}

.file-picker-container {
    border: 1px solid #ddd;
    min-height: 50px;
    padding: 5px;
    margin-bottom: 5px;
}

.full-width {
    width: 100%;
}

.promotion-item {
    width: 500px;
    overflow: hidden;
    border: 1px solid #ddd;
    padding: 5px;
}

.promotion-item .body {
    position: relative;
    float: left;
}

.promotion-item .footer {
    position: relative;
    float: left;
    width: 100%;
}

.promotion-item img.logo {
    float: left;
    width: 100px;
}

.promotion-item img.promotion-image {
    width: 100%;
    min-height: 200px;
}

.promotion-item .badge {
    background-color: red;
    padding: 15px;
    color: white;
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.shop-item {
    border-top: 1px solid #ddd;
    padding: 5px;
    float: left;
    width: 100%;
}

pre {
    float: left;
    width: 100%;
    font-family: inherit !important;
    font-size: inherit !important;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    text-align: justify;
}

.order-item {
    margin-top: 15px;
    padding-left: 100px;
    position: relative;
    overflow: hidden;
    height: 100px;
}

.order-item img {
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
}

td.active {
    font-weight: bold;
    color: #f86c6b;
    background-color: #e4e7ea;
}

.table th,
.table td {
    padding: 5px;
}

.orderable-list {
    border: 1px solid #ddd;
    width: 100%;
}

.orderable-list .item {
    border-bottom: 1px solid #ddd;
    padding: 5px;
    background-color: white;
}

.orderable-list .item:hover {
    background-color: #e4e7ea;
}

.orderable-list .item.active {
    background-color: #e4e7ea;
    font-weight: bold;
    color: #f86c6b;
}

.orderable-list .item.header {
    font-weight: bold;
    background-color: #c8ced3;
    height: 36px;
    line-height: 36px;
    padding: 0;
    padding-left: 5px;
}

/* .sidebar .nav-dropdown.open>.nav-dropdown-items {
    padding-left: 20px;
} */

.rbc-calendar {
    height: 700px !important;
    max-height: 100%;
}

.rbc-event {
    /* background-color: transparent !important; */
    /* color: #239a06 !important; */
}

.login-intro {
    text-align: justify;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}

.control-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.single-image-container {
    display: inline-block;
    padding: 5px;
    border: 1px solid #ddd;
    position: relative;
}

.btn-remove-image {
    position: absolute;
    top: 0;
    right: 0;
}

.form-control.select-container {
    height: 42px;
}

.btn {
    text-transform: uppercase;
}

.data-table .input-group-prepend .btn,
.data-table .input-group-append .btn {
    z-index: 0;
}

.ReactTable .rt-thead.-filters .rt-th {
    overflow: visible;
}

.select-container .select-item {
    float: left;
    margin-bottom: 2px;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.captcha {
    float: left;
    display: inline;
}

.modal-title {
    width: 100%;
}

img.modal-logo {
    height: 32px;
    float: right;
}

.img-full {
    width: 100%;
    height: auto;
}

.text-wrap {
    word-wrap: break-word;
}

.phone-simulator {
    border: 10px solid #919191;
    padding: 5px;
    border-radius: 15px;
    max-width: 420px;
    margin: auto;
    width: 100%;
    max-height: 600px;
    overflow: auto;
}

.banner-container {
    position: relative;
}

.banner-container img {}

.voucher-value {
    position: absolute;
    bottom: 0;
    background-color: #0000007a;
    width: 100%;
    color: white;
    padding: 0 10px;
    font-weight: bold;
}

.modal-lg {
    max-width: 1024px;
}

img.partner-logo {
    position: absolute;
    right: 10px;
    width: 56px;
    top: 10px;
}



.form-label-horizontal {
    text-align: right;
}

.react-datepicker--time-only .react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box,
.react-datepicker__time-container {
    width: 100px;
}

.react-datepicker {
    width: 346px;
}

.ReactModal__Overlay {
    z-index: 2000;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    overflow: auto;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal-container {
    background-color: white;
    padding: 15px;
    border-radius: 4px;
    min-width: 400px;
    padding-top: 50px;
    position: relative;
    overflow: hidden;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    box-shadow: none;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Content {
    top: 0;
}

.modal-head {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #c3c3c3;
    width: 100%;
    font-weight: bold;
    padding: 10px;
}

.modal-head p {
    margin: 0
}

.modal-head span {
    cursor: pointer;
    padding: 10px 20px;
    background-color: #ff6e6e;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
}

.modal {
    z-index: 2500;
}

.sidebar .nav {
   
    background-image: linear-gradient(#f04963,#f3734c);
}

.sidebar .nav-link .nav-icon {

    color: white;
}

.sidebar .nav-link.active {
    background: rgba(0,0,0,0.3);
}

.sidebar .nav-link.active .nav-icon {
    color: #fff;
}

.sidebar .nav-link:hover {
    color: #fff;
    background: rgba(0,0,0,0.2);
}

.sidebar {
    background-image: linear-gradient(#f04963,#f3734c);
}

.btn-outline-primary {
    color: #f04963;

    border-color: #f04963;
}

.btn-login {
    background-image: linear-gradient( to left,#f04963,#f3734c);
    color: #fff !important;
    border: none;
    height: 45px;
}

.btn-primary {
    background-image: linear-gradient( to left,#f04963,#f3734c);
    box-shadow: none;
    outline: none;
    border: none;
}

.switch-primary .switch-input:checked + .switch-slider {
    background-image: linear-gradient( to left,#f04963,#f3734c);
    box-shadow: none;
    outline: none;
    border: none;
}

.switch-primary .switch-input:checked + .switch-slider::before {
    border-color: #f04963;
}

.sidebar .nav-dropdown-toggle::before {
    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E")
}

.sidebar .sidebar-minimizer::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E")
}

a {
    color:#f04963 ;
}



/* /////// */




body {
    background-color: #f6f7f8;
   
}
.header-fixed .app-header{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), -1px 0px 4px rgba(0, 0, 0, 0.06), 0px 1px 4px rgba(0, 0, 0, 0.06);
}
.bg-image {
    /* background-image: url('./assets/img/bg.jpg'); */
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100%;
}





.list-item-img {
    max-width: 120px;
}


img.file-picker-thumbnail {
    width: 100%;
    max-width: 200px;
    padding: 5px;
    border: 1px solid #ddd;
    margin-bottom: 2px;
}

.multiple-remove-button {
    position: absolute;
    right: 20px;
    top: 5px;
}

.file-picker-container {
    border: 1px solid #ddd;
    min-height: 50px;
    padding: 5px;
    margin-bottom: 5px;
}

.full-width {
    width: 100%;
}

.promotion-item {
    width: 500px;
    overflow: hidden;
    border: 1px solid #ddd;
    padding: 5px;
}

.promotion-item .body {
    position: relative;
    float: left;
}

.promotion-item .footer {
    position: relative;
    float: left;
    width: 100%;
}

.promotion-item img.logo {
    float: left;
    width: 100px;
}

.promotion-item img.promotion-image {
    width: 100%;
    min-height: 200px;
}

.promotion-item .badge {
    background-color: red;
    padding: 15px;
    color: white;
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.shop-item {
    border-top: 1px solid #ddd;
    padding: 5px;
    float: left;
    width: 100%;
}

pre {
    float: left;
    width: 100%;
    font-family: inherit !important;
    font-size: inherit !important;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    text-align: justify;
}

.order-item {
    margin-top: 15px;
    padding-left: 100px;
    position: relative;
    overflow: hidden;
    height: 100px;
}

.order-item img {
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
}

td.active {
    font-weight: bold;
    color: #f86c6b;
    background-color: #e4e7ea;
}

.table th,
.table td {
    border: none;
}

.orderable-list {
    border: 1px solid #ddd;
    width: 100%;
}

.orderable-list .item {
    border-bottom: 1px solid #ddd;
    padding: 5px;
    background-color: white;
}

.orderable-list .item:hover {
    background-color: #e4e7ea;
}

.orderable-list .item.active {
    background-color: #e4e7ea;
    font-weight: bold;
    color: #f86c6b;
}

.orderable-list .item.header {
    font-weight: bold;
    background-color: #c8ced3;
    height: 36px;
    line-height: 36px;
    padding: 0;
    padding-left: 5px;
}













/* .sidebar .nav-dropdown.open>.nav-dropdown-items {
    padding-left: 20px;
} */

.rbc-calendar {
    height: 700px !important;
    max-height: 100%;
}

.rbc-event {
    /* background-color: transparent !important; */
    /* color: #239a06 !important; */
}

.login-intro {
    text-align: justify;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}

.control-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.single-image-container {
    display: inline-block;
    padding: 5px;
    border: 1px solid #ddd;
    position: relative;
}

.btn-remove-image {
    position: absolute;
    top: 0;
    right: 0;
}

.form-control.select-container {
    height: 42px;
    overflow:hidden;
}

/* .btn {
    text-transform: uppercase;
} */

.data-table .input-group-prepend .btn,
.data-table .input-group-append .btn {
    z-index: 0;
}

.ReactTable .rt-thead.-filters .rt-th {
    overflow: visible;
}

.select-container .select-item {
    float: left;
    margin-bottom: 2px;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.captcha {
    float: left;
    display: inline;
}

.modal-title {
    width: 100%;
}

img.modal-logo {
    height: 32px;
    float: right;
}

.img-full {
    width: 100%;
    height: auto;
}

.text-wrap {
    word-wrap: break-word;
}

.phone-simulator {
    border: 10px solid #919191;
    padding: 5px;
    border-radius: 15px;
    max-width: 420px;
    margin: auto;
    width: 100%;
    max-height: 600px;
    overflow: auto;
}

.banner-container {
    position: relative;
}

.banner-container img {}

.voucher-value {
    position: absolute;
    bottom: 0;
    background-color: #0000007a;
    width: 100%;
    color: white;
    padding: 0 10px;
    font-weight: bold;
}

.modal-lg {
    max-width: 1024px;
}

img.partner-logo {
    position: absolute;
    right: 10px;
    width: 56px;
    top: 10px;
}

.form-label-horizontal {
    text-align: right;
}

.react-datepicker--time-only .react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box,
.react-datepicker__time-container {
    width: 100px;
}

.react-datepicker {
    width: 346px;
}

.ReactModal__Overlay {
    z-index: 2000;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    overflow: auto;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal-container {
    background-color: white;
    padding: 15px;
    border-radius: 4px;
    min-width: 400px;
    padding-top: 50px;
    position: relative;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Content {
    top: 0;
}

.modal-head {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #c3c3c3;
    width: 100%;
    font-weight: bold;
    padding: 10px;
}

.modal-head p {
    margin: 0
}

.modal-head span {
    cursor: pointer;
    padding: 10px 20px;
    background-color: #ff6e6e;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
}

.modal {
    z-index: 2500;
}

img.tier-thumb {
    height: 46px;
}

.f-left {
    float: right;
}

.DateInput_input {
    padding: 0 11px;
}

.sidebar-fixed .sidebar,
.sidebar .nav,
.sidebar .sidebar-nav {
    width: 200px;
}

html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer {
    margin-left: 250px;
}



.brand-card-body div {
    text-align: left;
    padding: 0px 5px;
}

.rthfc .rt-th {
    background-color: #E9EAED;
    font-weight: 500;
    text-align: left;
    color: #203048 !important;
    padding: 10px 5px !important;
}



.btn-secondary ,.btn-default{
    color: #23282c;
    background-color: #FFFFFF;
    border: 0.5px solid #D2D6DA;
    border-radius:4px;
}

.btn {
    font-weight: 500;
}

.back-btn {
    color: #798391;
    text-decoration: underline;
    cursor: pointer;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.modal-lg{
    max-width: 90%;
}
.content-right{
    text-align: right;
}
.rthfc .rt-th{
    padding:8px 12px !important;
}


.full-text-right{
    width:100%;
    text-align: right;
    float:left;
}
.form-label-horizontal{
    text-align:left;
}
.app-header .navbar-brand{
    padding-left: 24px;
}
.ReactTable .rt-td, .ReactTable .rt-th{
    padding: 12px;
}

.switch-slider{
    border-radius: 0.75rem;
}
.switch-slider::before{
    border-radius: 0.75rem;
}

.ReactTable .rt-tbody .rt-td:last-child{
overflow: visible;
}

.dropdown-menu{
    border: none;
}
.dropdown-menu.show {
    display: block;    
    padding: 5px 1px 5px 10px;
    margin-top: -5px;
    margin-right: 5px;
}
.btn-transparent{
    color: gray;
}
.noti-header{
    width:500px;
}




